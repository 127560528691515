<template>
	<router-view></router-view>
</template>

<script>
	export default {
		name: 'App',
		setup() {

		}
	}
</script>

<style lang="less">
	@import '@/style/style.less';
	.abow-dialog{
		.el-dialog__body{
			height: calc(100% - 54px) !important;
		}
	} 
</style>