import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import { ElNotification } from 'element-plus';
import config from "@/config"
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import tool from '@/utils/tool';
import systemRouter from './systemRouter';
import { getOauthConfig } from '@api/public'

//系统路由
const routes = systemRouter

//系统特殊路由
const routes_404 = {
	path: "/:pathMatch(.*)*",
	hidden: true,
	component: () => import(/* webpackChunkName: "404" */ '@/views/other/404'),
}

const router = createRouter({
	history: process.env.NODE_ENV === 'production' ? createWebHistory(process.env.BASE_URL) : createWebHashHistory(process.env.BASE_URL),
	routes: routes
})

//设置标题
document.title = config.APP_NAME

//判断是否已加载过API路由
var isGetApiRouter = false;

router.beforeEach(async (to, from, next) => {
	NProgress.start()
	console.log(to)
	//动态标题
	document.title = `${to.meta.title} - ${config.APP_NAME}`

	let userInfo = tool.data.get("user");
	if(to.path === "/login"){
		isGetApiRouter = false;
		next();
		return false;
	}
	if(!userInfo && !to.meta.isLogin){
		next({
			path: '/login'
		});
		return false;
	}
	if(to.path.includes('/wechatInfo')){
		if(!tool.data.get('user')){
			let { corp_id, start_time, end_time, time_type, userId, agent_id = 0 } = to.query
			let redirect_uri = `/dist${to.path}`
			if(start_time || end_time || time_type){
				redirect_uri = `${redirect_uri}?start_time=${start_time}&end_time=${end_time}&time_type=${time_type}`
			}
			if(userId){
				redirect_uri = `${redirect_uri}?userId=${userId}`
			}

			if(!to.query.code){
				let res = await getOauthConfig({ corp_id, redirect_uri, agent_id })
				const { code } = res
				if(code === 200){
					window.location.href = res.data.url + '&corp_id=' + corp_id + '&agent_id=' + agent_id
				}
			}
		}else{
			next()
		}
	}
	// if(to.path === '/invite'){

	// }

	//加载API路由
	if(!isGetApiRouter && !to.meta.isLogin){
		var apiRouter = filterAsyncRouter(userInfo.menuList);
		apiRouter.forEach(item => {
			router.addRoute("layout", item)
		})
		router.addRoute(routes_404)
		if (to.matched.length == 0) {
			router.push(to.fullPath);
		}
		isGetApiRouter = true;
	}
	next();
});
router.afterEach(() => {
	NProgress.done()
});

router.onError((error) => {
	NProgress.done()
	//解决路由跳转加载静态资源失败的问题
	const pattern = /Loading chunk (\d)+ Failed/g
	const isChunkLoadFailed = error.message.match(pattern)
	const targetPath = router.history.pending.fullPath
	if (isChunkLoadFailed) {
		router.replace(targetPath)
	}
	ElNotification.error({
		title: '路由错误',
		message: error.message
	});
});


//转换
function filterAsyncRouter(routerMap) {
	const accessedRouters = []
	routerMap.forEach(item => {
		item.meta = item.meta?item.meta:{};
		//处理外部链接特殊路由
		if(item.meta.type=='iframe'){
			item.meta.url = item.path;
			item.path = `/i/${item.name}`;
		}
		//MAP转路由对象
		var route = {
			path: item.path,
			name: item.name,
			meta: item.meta,
			redirect: item.redirect,
			children: item.children ? filterAsyncRouter(item.children) : null,
			component: loadComponent(item.component)
		}
		accessedRouters.push(route)
	})
	return accessedRouters
}
function loadComponent(component){
	if(component){
		return () => import(/* webpackChunkName: "[request]" */ `@/views/${component}`)
	}else{
		return () => import(`@/views/other/empty`)
	}

}


export default router
