//数据表格配置

export default {
	pageSizes:[20, 40, 60,100,200,500],//页码大小
	pageSize: 20,						//表格每一页条数
	parseData: function (res) {			//数据分析
		return {
			rows: res.data,				//分析行数据字段结构
			total: res.count,			//分析总数字段结构
			msg: res.msg,			//分析描述字段结构
			code: res.code				//分析状态字段结构
		}
	},
	request: {							//请求规定字段
		page: 'page',					//规定当前分页字段
		pageSize: 'page_size',			//规定一页条数字段
		sort_field: 'sort_field',					//规定排序字段名字段
		sort_type: 'sort_type'					//规定排序规格字段
	}
}
