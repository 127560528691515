<template>
	<div class="scTable" ref="scTableMain" v-loading="loading">
		<div class="scTable-table">
			<el-table :data="tableData" :size='size' border :row-key="rowKey" :key="toggleIndex" ref="scTable" :height="tableHeight" :stripe="stripe" :highlight-current-row="highlightCurrentRow"  @selection-change="selectionChange" @current-change="currentChange" @sort-change="sortChange" @filter-change="filterChange" @row-click='rowClick' @select='select' @select-all='selectAll' @getotherData='getotherData'>
				<slot></slot>
				<el-table-column v-for="(item, index) in userColumn" :key="index" :label="item.label" :prop="item.prop" :width="item.width">
					<template #default="scope">
						{{scope.row[item.prop]}}
					</template>
					<template #header>
						{{item.label}}
						<i class="el-icon-remove" style="color: #F56C6C;cursor: pointer;"></i>
					</template>
				</el-table-column>
				<el-table-column min-width="1"></el-table-column>
				<template #empty>
					<el-empty :description="emptyText" :image-size="100"></el-empty>
				</template>
			</el-table>
		</div>
		<div class="scTable-page">
			<div class="scTable-pagination">
				<el-pagination :size='size' v-if="!hidePagination" background :layout="paginationLayout" :total="total" :page-sizes="pageSizes" :page-size="pageSize" v-model:currentPage="currentPage" @current-change="reload" @size-change="handleSizeChange"></el-pagination>
			</div>
			<!-- <div class="scTable-do" v-if="!hideDo">
				<el-button @click="refresh" icon="el-icon-refresh" circle style="margin-left:15px"></el-button>
				<el-popover placement="top" title="设置" :width="500" trigger="click">
					<template #reference>
						<el-button icon="el-icon-setting" circle style="margin-left:15px"></el-button>
					</template>
					<columnSetting ref="columnSetting" @userChange="columnSettingChange" :column="column"></columnSetting>
				</el-popover>
			</div> -->
		</div>
	</div>
</template>

<script>
	// import Sortable from 'sortablejs'
	import config from "@/config/table"
	// import columnSetting from './columnSetting'
	import Tool from '@utils/tool.js'
    import { ElNotification } from 'element-plus'
	import {toRefs,reactive,defineComponent,onActivated,onDeactivated,nextTick,onMounted,watch,getCurrentInstance} from 'vue'
	export default defineComponent({
		name:'scTable',
		// components:{columnSetting},
		props: { 
			apiObj: { type: Object, default: () => {} },
			params: { type: Object, default: () => ({}) },
			data: { type: Object, default: () => {} },
			rowKey: { type: String, default: "" },
			column: { type: Object, default: () => {} },
			remoteSort: { type: Boolean, default: false },
			remoteFilter: { type: Boolean, default: false },
			hidePagination: { type: Boolean, default: false },
			hideDo: { type: Boolean, default: false },
			stripe: { type: Boolean, default: false },
			highlightCurrentRow: { type: Boolean, default: false },
			paginationLayout: { type: String, default: "total, sizes ,prev, pager, next, jumper" },
			setDefaultSelectTableList:{type:Array,default:()=>[]},
			isClickHighLight:{type:Boolean,default:false},//是否点击高亮
		},
		setup(props,ctx){
			let { appContext } = getCurrentInstance()
			let CONFIG = appContext.config.globalProperties.$CONFIG
			let data = reactive({
				size:CONFIG.SIZE,
				columnSetting:{},
				scTable:{},
				scTableMain:{},
				emptyText: "暂无数据",
				toggleIndex: 0,
				tableData: [],
				pageSize: 20,
				pageSizes:config.pageSizes,
				total: 0,
				currentPage: 1,
				prop: '',
				order: '',
				loading: false,
				tableHeight: '100%',
				userColumn: [],
				selectID:[]
			})
			watch(()=>props.data,()=>{
				data.tableData = props.data
				data.total = data.tableData.length
			},{deep:true})

			watch(()=>props.params,()=>{
				refresh()
			})
			const upTableHeight = ()=>{
				data.tableHeight = (data.scTableMain.offsetHeight - 50 ) + "px"
			}

			const getData = async ()=>{
				data.scTable.clearSelection()
				data.scTable.setCurrentRow()
				data.loading = true
				let reqData = {}
				reqData = {
					[config.request.page]: data.currentPage,
					[config.request.pageSize]: data.pageSize || config.pageSize,
					[config.request.sort_field]: data.prop,
					[config.request.sort_type]: data.order
				}
				reqData = {...reqData,...props.params}
				//不要分页
				if(props.hidePagination){
					reqData = {...props.params}
				}
				try {
					var res = await props.apiObj.get(reqData)
				}catch(error){
					data.loading = false
					data.emptyText = error.statusText
					return false
				}
				var response = config.parseData(res)
				if(response.code != 200){
					data.loading = false
					data.emptyText = response.msg
					ElNotification.error({title:'提示',message:response.msg,type:'error'})
				}else{
					data.scTable.$el.querySelector('.el-table__body-wrapper').scrollTop = 0
					data.emptyText = "暂无数据"
					data.tableData = response.rows
					ctx.emit('getotherData', response)
					data.total = response.total
					data.loading = false
					//是否需要默认选中表格中的数据
					nextTick(()=>{
						data.tableData && data.tableData.forEach(item1=>{
							props.setDefaultSelectTableList && props.setDefaultSelectTableList.forEach(item=>{
								if(item == item1.rule_id){
									data.scTable.toggleRowSelection(item1,true)
								}
							})
							item1.uuid = Tool.getUuid()
						})
					})
				}
			}

			const reload = ()=>{
				getData()
			}
			const handleSizeChange = (e)=>{
				data.pageSize = e
				refresh()
			}
			//刷新数据
			const refresh = ()=>{
				data.currentPage = 1
				// data.scTable.clearSelection()
				getData()
			}
			//更新数据
			const upData = (params)=>{
				data.currentPage = 1
				Object.assign(props.params, params || {})
				getData()
			}
			//自定义变化事件
			const columnSettingChange = (userColumn)=>{
				data.userColumn = userColumn
				data.toggleIndex += 1
			}
			// const removeColumn = (index)=>{
			// 	columnSetting.remove(index)
			// 	data.toggleIndex += 1;
			// }
			//排序事件
			const sortChange = (obj)=>{
				console.log(obj)
				// if(!props.remoteSort){
				// 	return false
				// }
				if(obj.column && obj.prop){
					data.prop = obj.prop
					data.order = obj.order == 'ascending' ? 'asc' : 'desc'
				}else{
					data.prop = ''
					data.order = ''
				}
				getData()
			}
			//过滤事件
			const filterChange = (filters)=>{
				if(!data.remoteFilter){
					return false
				}
				Object.keys(filters).forEach(key => {
					filters[key] = filters[key].join(',')
				})
				upData(filters)
			}
			//转发原装方法&事件
			const selectionChange = (selection)=>{
				ctx.emit('selection-change', selection)
				data.selectID = selection && selection.filter(item=>{return item.uuid})
			}
			const currentChange = (selection)=>{
				ctx.emit('current-change', selection)
			}
			const select = (Selection)=>{
				ctx.emit('select',Selection)
			}
			const selectAll = (Selection)=>{
				ctx.emit('select-all',Selection)
			}

			//点击行事件
			const rowClick = (selection)=>{
				if(!props.isClickHighLight){
					return
				}
				data.scTable.toggleRowSelection(selection)
				data.scTable.setCurrentRow(selection)
				ctx.emit('row-click',selection)
			}
			nextTick(()=>{
				upTableHeight()
			})

			onMounted(()=>{
				// columnDrop()
				if(data.apiObj){
					data.getData()
				}else if(data.data){
					data.tableData = data.data
					data.total = data.tableData.length
				}
			})

			onActivated(()=>{
				nextTick(() => {
					upTableHeight()
				})
				window.addEventListener("resize", upTableHeight, true)
			})

			onDeactivated(()=>{
				window.removeEventListener("resize", upTableHeight, true)
			})

			//拖拽列事件
			// const columnDrop = ()=>{
			// 	const wrapperTr = document.querySelector('.el-table__header-wrapper tr')
			// 	Sortable.create(wrapperTr,{
			// 		animation: 180,
			// 		delay: 0,
			// 		onEnd:evt=>{
			// 			console.log(evt)
			// 			data.scTable.doLayout()
			// 			// const targetRow = data.tableData.splice(evt.oldIndex, 1)[0]
			// 			// data.tableData.splice(evt.newIndex, 0, targetRow)
			// 		}
			// 	})
			// }

			return{
				// columnDrop,
				...toRefs(data),
				refresh,
				columnSettingChange,
				// removeColumn,
				sortChange,
				filterChange,
				selectionChange,
				currentChange,
				reload,
				upData,
				rowClick,
				handleSizeChange,
				select,
				selectAll
			}
		}
	})
</script>

<style scoped>
	.scTable {display:flex;flex-direction:column;height:100%;}
	.scTable-table {flex:1;}
	.scTable-page {height:50px;display: flex;align-items: center;justify-content: space-between;padding:0 15px;}
	.scTable-do {white-space: nowrap;}
</style>
