//系统路由
const routes = [{
		name: "layout",
		path: "/",
		component: () => import(/* webpackChunkName: "layout" */ '@/layout'),
		redirect: '/dashboard',
		children: [
			{
				name: "home",
				path: "/home",
				component: () => import(`@/views/other/empty`),
				meta: {
					title: "首页",
					icon: "el-icon-platform-eleme"
				},
				children: [
					{
						name: "dashboard",
						path: "/dashboard",
						meta: {
							title: "首页",
							icon: "el-icon-menu",
							affix: true
						},
						component: () => import(/* webpackChunkName: "home" */ '@/views/home'),
					}
				]
			}
		]
	},
	{
		path: "/cmd",
		component: () => import(/* webpackChunkName: "cmd" */ '@/views/other/cmd'),
		meta: {
			title: "CMD"
		}
	},
	{
		path: "/login",
		component: () => import(/* webpackChunkName: "login" */ '@/views/userCenter/login'),
		meta: {
			title: "登录"
		}
	},
	// {
	// 	path: "/wechatInfo",
	// 	component: () => import(/* webpackChunkName: "login" */ '@/views/wechatInfo/indexPage'),
	// 	meta: {
	// 		title: "微信页面",
	// 		isLogin:true
	// 	}
	// },
	{
		path:'/invite',
		component:()=>import('@/views/wechatInfo/invite'),
		meta:{
			title:'邀请好友',
			isLogin:true
		}
	},{
		path:'/radar',
		component:()=>import('@/views/wechatInfo/radar'),
		meta:{
			title:'客户雷达',
			isLogin:true
		}
	},
	{
		path:'/poster',
		component:()=>import('@/views/wechatInfo/invite/poster.vue'),
		meta:{
			title:'分享海报',
			isLogin:true
		}
	},
	{
		path:'/invite/qunCode',
		component: () =>import('@/views/wechatInfo/invite/qunCode.vue'),
		meta:{
			title:'分享群',
			isLogin:true
		}
	},{
		path: "/wechatInfo/code",
		component: () => import('@/views/wechatInfo/customize/code'),
		meta: {
			title: "一客一码",
			isLogin:true
		}
	},{
		path: "/wechatInfo/goodsList",
		component: () => import('@/views/wechatInfo/customize/goodsList'),
		meta: {
			title: "商品列表",
			isLogin:true
		}
	},{
		path: "/wechatInfo/wordsLibrary",
		component: () => import('@/views/wechatInfo/customize/wordsLibrary'),
		meta: {
			title: "话术库",
			isLogin:true
		}
	},{
		path: "/wechatInfo/portrait",
		component: () => import('@/views/wechatInfo/customize/portrait'),
		meta: {
			title: "客户画像",
			isLogin:true
		}
	},{
		path: "/wechatInfo/userInfo",
		component: () => import('@/views/wechatInfo/customize/userInfo'),
		meta: {
			title: "客户信息",
			isLogin:true
		}
	},{
		path: "/wechatInfo/order",
		component: () => import('@/views/wechatInfo/customize/order'),
		meta: {
			title: "订单列表",
			isLogin:true
		}
	},{
		path: "/wechatInfo/marketingList",
		component: () => import('@/views/wechatInfo/customize/marketingList'),
		meta: {
			title: "营销配置",
			isLogin:true
		}
	},{
		path: "/wechatInfo/wxlg",
		component: () => import('@/views/wechatInfo/customize/wxlg'),
		meta: {
			title: "登录",
			isLogin:true
		}
	},{
		path: "/wechatInfo/achievement",
		component: () => import('@/views/wechatInfo/customize/achievement'),
		meta: {
			title: "业绩管理",
			isLogin: true
		}
	},{
		path: "/wechatInfo/summaryData",
		meta: {
			title: "客户小结",
			isLogin:true
		}
	},{
		path: "/wechatInfo/actionItems",
		component: () => import('@/views/wechatInfo/customizedTm/actionItems'),
		meta: {
			title: "代办事项",
			isLogin: true
		}
	},{
		path: "/wechatInfo/accountPage",
		component: () => import('@/views/wechatInfo/customizedTm/accountPage'),
		meta: {
			title: "马达审核",
			isLogin: true
		}
	},{
		path: "/wechatInfo/leadEntry",
		component: () => import('@/views/wechatInfo/customizedTm/leadEntry'),
		meta: {
			title: "线索录入",
			isLogin: true
		}
	},{
		path: "/wechatInfo/info",
		component: () => import('@/views/wechatInfo/customizedTm/info'),
		meta: {
			title: "基础信息",
			isLogin: true
		}
	}

]

export default routes;
