 
import config from "@/config"
import http from "@/utils/request"
import tool from '@utils/tool'

//获取公共数据
const getOrderSelectData = async (params) =>{
	return await http.post(`${config.API_URL}/admin/public/orderSelectData`,params)
}

//上传文件公共接口
const publicUploadFile = `${config.API_URL}/index/public/upload`


//获取微信授权信息
const getWechatAutnInfo = async ()=>{
	console.log(tool.data.get('user'))
	// index/open_work_wechat/jsapi 权限id 112
	let res = await http.post(`${config.API_URL}/index/open_work_wechat/jsapi`,{url:window.location.href.split('#')[0]})
	
	const { code,data } = res
	const { corpid,agentid,timestamp,nonceStr,signature } = {...data}
	if(code === 200){
		return new Promise((resolve, reject)=>{
			window.wx.agentConfig({
				corpid,
				agentid,
				timestamp,
				nonceStr,
				signature,
				jsApiList: ['selectExternalContact','getCurExternalContact','sendChatMessage','getContext','getCurExternalChat','navigateToAddCustomer','getCurExternalChat'],
				success:async(res)=>{
					resolve(res)
				},
				fail:(res)=>{
					reject(res)
				}
			})
		})
	}
}

//获取公司组织架构
const getComanyInfo = async ()=>{
	let res1 = await http.get(`${config.API_URL}/index/user/departmentUserTree`)
	let list = []
	if(res1.code === 200){
		list = res1.data
	}
	return list
}
//获取登录授权接口
const getAuthUrl = async params =>{
	return await http.get(`${config.API_URL}/index/login/qylogin`,params)
}

//应用授权接口
const getAppAdd = async params=>{
	return await http.get(`${config.API_URL}/index/login/addApp`,params)
}

//微信网页授权静默登录获取地址
const getOauthConfig = async params=>{
	return await http.get(`${config.API_URL}/index/login/qyoauth`,params)
}

//后台admin登录
const adminLogin = async params=>{
	return await http.get(`${config.API_URL}/admin/login/login`,params)
}

//自动登录
const indexLogin = async params=>{
	return await http.get(`${config.API_URL}/index/login/login`,params)
}


//获取员工list
const getDepartmentUserList = async (params)=>{
	let res1 = await http.get(`${config.API_URL}/index/user/departmentUserList`,params)
	let list = []
	if(res1.code === 200){
		list = res1.data
	}
	return list
}
//获取部门列表
const getDepartmentList = async ()=>{
	let res1 = await http.get(`${config.API_URL}/index/user/departmentUserTree?no_tree=1`)
	let list = []
	if(res1.code === 200){
		list = res1.data
	} 
	return list
}

export { getOrderSelectData,publicUploadFile,getWechatAutnInfo,getComanyInfo,getAuthUrl,getOauthConfig,getAppAdd,adminLogin,indexLogin,getDepartmentUserList, getDepartmentList}
