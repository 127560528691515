/*
 * @Descripttion: 工具集
 * @version: 1.1
 * @LastEditors: heliuchun
 * @LastEditTime: 2022-08-01 18:54:45
 */

import CryptoJS from 'crypto-js'
import { ElMessage } from 'element-plus'
import moment from 'moment'

const tool = {}

//倒计时
tool.countDown = (endTime,startTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))=>{
	let endNumber = moment(endTime).valueOf()
	let startNumber = moment(startTime).valueOf()
	let nowNumber = moment(new Date()).valueOf()
	if(startNumber > nowNumber){
		return '还未开始'
	}
	if(nowNumber > endNumber){
		return '已结束'
	}
	const obj = moment.duration(endNumber - nowNumber, 'milliseconds')
	let day = obj.days()
	let hours = obj.hours()
	let minutes = obj.minutes()
	let seconds = obj.seconds()
	return { str: `${day}天${hours}时${minutes}分${seconds}秒`, type: 'object' }
}


//企业微信使用
//发送消息到当前会话
tool.sendInfoToSession = (data)=>{
	let obj = {msgtype:'text',enterChat:true}
	if(Array.isArray(data)){
		obj = {msgtype:data[0].msgtype,enterChat:true}
		data && data.forEach(item=>{
			Object.assign(obj,item)
		})
	}else{
		let { miniprogram } = data
		let miniprogram1 = {}

		if(miniprogram){
			miniprogram1 = {
				appid:miniprogram.appid,
				title:miniprogram.title,
				imgUrl:miniprogram.imgUrl,
				page:miniprogram.page
			}
			Object.assign(obj,{miniprogram:miniprogram1})
			obj.msgtype = 'miniprogram'
		}
	}
	// return new Promise(resolve => {
		window.wx.invoke('sendChatMessage',obj,res=>{
			console.log(res)
			if (res.err_msg == 'sendChatMessage:ok') {
				//发送成功
			}else{
				if(res.err_msg === 'sendChatMessage:fail_not allow to cross corp'){
					ElMessage.error('公司主体不相同不能发送相关信息')
				}
			}
			// resolve(res)
		})
	// })
}

//防抖
tool.debounce = (func, wait = 300, immediate)=> {
	var timeout, result;
	return function () {
		var context = this
		var args = arguments
		if (timeout) clearTimeout(timeout)
		if (immediate) {
			// 如果已经执行过，不再执行
			var callNow = !timeout
			timeout = setTimeout(function(){
				timeout = null
			}, wait)
			if (callNow) result = func.apply(context, args)
		}
		else {
			timeout = setTimeout(function(){
				func.apply(context, args)
			}, wait)
		}
		return result
	}
}
//生成uuid
tool.getUuid = () => {
	let uuid = Date.now() + '' + Math.floor(Math.random() * 1e7)
	return uuid
}

//把form对象种的数组处理成字符串拼接
tool.dealFormObj = params => {
	let obj = {
		...params
	}
	for (let key in obj) {
		if (Array.isArray(obj[key])) {
			obj[key] = obj[key].join(',')
		}
	}
	return obj
}

//查询条件的参数拼接，用于导出
tool.searchFormDataDeal = (obj = {}) => {
	let str = ''
	if (Object.keys(obj).length) {
		for (let key in obj) {
			str += key + '=' + obj[key] + '&'
		}
		return str.slice(0, str.length - 1)
	} else {
		return ''
	}
}

/*数据求和*/
tool.dealSum = param => {
	let data = 0
	if (typeof param == 'string' || typeof param == 'number') {
		data = Math.round(Number(data) * 100)
	} else {
		param && param.forEach(item => {
			data += Math.round(Number(item) * 100)
		})
	}
	return data / 100
}
//递归遍历树形数据
tool.findDep = (datas) => {
	for (var i in datas) {
		datas[i].nodeName = datas[i].meta.title
		if (!datas[i].children.length) {
			delete datas[i].children
		}
		if (datas[i].children) {
			tool.findDep(datas[i].children);
		}
	}
	return datas
}

/* sessionStorage */
tool.data = {
	set(table, settings) {
		var _set = JSON.stringify(settings)
		return sessionStorage.setItem(table, _set);
	},
	get(table) {
		var data = sessionStorage.getItem(table);
		try {
			data = JSON.parse(data)
		} catch (err) {
			return null
		}
		return data;
	},
	remove(table) {
		return sessionStorage.removeItem(table);
	},
	clear() {
		return sessionStorage.clear();
	}
}

/* Fullscreen */
tool.screen = function(element) {
	var isFull = !!(document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement ||
		document.fullscreenElement);
	if (isFull) {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}
	} else {
		if (element.requestFullscreen) {
			element.requestFullscreen();
		} else if (element.msRequestFullscreen) {
			element.msRequestFullscreen();
		} else if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
		} else if (element.webkitRequestFullscreen) {
			element.webkitRequestFullscreen();
		}
	}
}

/* 复制对象 */
tool.objCopy = function(obj) {
	return JSON.parse(JSON.stringify(obj));
}

/* 日期格式化 */
tool.dateFormat = function(date, fmt = 'yyyy-MM-dd') {
	date = new Date(date)
	var o = {
		"M+": date.getMonth() + 1, //月份
		"d+": date.getDate(), //日
		"h+": date.getHours(), //小时
		"m+": date.getMinutes(), //分
		"s+": date.getSeconds(), //秒
		"q+": Math.floor((date.getMonth() + 3) / 3), //季度
		"S": date.getMilliseconds() //毫秒
	};
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	}
	for (var k in o) {
		if (new RegExp("(" + k + ")").test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k])
				.length)));
		}
	}
	return fmt;
}

/* 千分符 */
tool.groupSeparator = function(num) {
	num = num + '';
	if (!num.includes('.')) {
		num += '.'
	}
	return num.replace(/(\d)(?=(\d{3})+\.)/g, function($0, $1) {
		return $1 + ',';
	}).replace(/\.$/, '');
}

/* 常用加解密 */
tool.crypto = {
	//MD5加密
	MD5(data) {
		return CryptoJS.MD5(data).toString()
	},
	//BASE64加解密
	BASE64: {
		encrypt(data) {
			return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data))
		},
		decrypt(cipher) {
			return CryptoJS.enc.Base64.parse(cipher).toString(CryptoJS.enc.Utf8)
		}
	},
	//AES加解密
	AES: {
		encrypt(data, secretKey) {
			const result = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey))
			return result.toString()
		},
		decrypt(cipher, secretKey) {
			const result = CryptoJS.AES.decrypt(cipher, CryptoJS.enc.Utf8.parse(secretKey))
			return CryptoJS.enc.Utf8.stringify(result);
		}
	}
}

export default tool
